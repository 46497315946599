<template>
  <v-app>
  
    <v-content>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Change Password</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
              <span class="" style="color:red">
            <center>    Your username and password are the same. So, as a security consideration, please change your password . The OTP will be sent to your institute email ID. 
            </center>   </span>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.uid"
                    :rules="[rules.required, rules.uid]"
                    prepend-icon="email"
                    placeholder="Enter Your Login Id"
                    clearable
                   readonly
                   disabled
                    required
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    id="enterOTP"
                    v-if="hidden1"
                    v-model="user.otp"
                    :rules="[v => !!v || 'OTP is required']"
                    prepend-icon="mdi-email-open"
                    placeholder="Enter your otp"
                    min="1"
                    max="6"
                    clearable
                    hint="Please check your mail & enter otp."
                    @change="btndisabled"
                    required
                  ></v-text-field>
                  <!-- New Layout instead of page routing -->
                  <v-text-field
                    id="newpwd"
                    v-if="hidden1"
                    v-model="newpwd"
                    prepend-icon="mdi-lock"
                    placeholder="Create new Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    hint="Combination of Numbers, alphabets and characters is recommended"
                    required
                  ></v-text-field>
                  <v-text-field
                    id="cnfmpwd"
                    v-if="hidden1"
                    v-model="cnfmpwd"
                    prepend-icon="mdi-lock"
                    placeholder="Re Enter new Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    hint="Re enter your new password"
                    required
                  ></v-text-field>
                  <div class="error-message" v-if="error">{{error_message}}</div>
                  <v-layout align-center justify-center v-if="hidden">
                    <v-btn class="mr-4" color="primary" @click="sendotp">
                      <v-icon>mdi-email-send-outline</v-icon>&nbsp;&nbsp; Send OTP
                    </v-btn>
                  </v-layout>
                  <div class="error-message" v-if="error1">{{error_message1}}</div>
                  <!-- <v-layout align-center justify-center v-if="hidden1">
                      <v-btn class="mr-4" color="primary" @click="verifyotp" :disabled="btnDisabled == true ? true : false">Verify OTP</v-btn>
                  </v-layout>-->
                  <!-- New layout instead of page routing -->
                  <v-layout align-center justify-center v-if="hidden1">
                    <v-btn
                      class="mr-4"
                      color="primary"
                      @click="changePassword"
                      :disabled="btnDisabled == true ? true : false"
                    >Change Password</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
              <router-link to="/" style="text-decoration: none" title="Back">
                <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  components: {
   
  },
  data() {
    return {
      user: {
        uid: "",
        otp: ""
      },
      rules: {
        required: value => !!value || "Required.",
        uid: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      hidden: true,
      hidden1: false,
      disabled: 0,
      btnDisabled: true,
      message: "",
      successMessage: "",
      dbotp: "",
      error: false,
      error_message: "",
      error1: false,
      error_message1: "",
      overlay: false,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      newpwd: "",
      cnfmpwd: "",
      value: String
    };
  },
  methods: {
    sendotp() {
      if (this.user.uid == "" ? "" : this.reg.test(this.user.uid)) {
        if (this.perormEmailFormValidation()) {
          //console.log("I am in If block :: " + this.user.uid);
          this.user.dns = window.location.hostname;
          this.overlay = true;
          axios
            .post("/login/forgotpassword", this.user)
            .then(res => {
              //window.console.log("res" + res.data.msg);
              if (res.data.msg == "200") {
                //console.log(res.data)
                this.overlay = false;
                this.error_message="";
                //this.successMessage=true;
                this.showSnackbar("#4caf50", "OTP has been sent on your Email..."); // show snackbar on error
                //this.successMessage = "OTP has been sent on your Email";
                localStorage.setItem("EPS-uid", res.data.uid);
                this.hidden = false;
                this.hidden1 = true;
                this.disabled = (this.disabled + 1) % 2;
                this.dbotp = res.data.otp;
              } else if (res.data.msg == "NAK") {
                //this.overlay = true;
                //this.message = "Email Id not found!";
                this.error = true;
                this.error_message = "Email address not found!";
                this.overlay = false;
              }else{
                 this.showSnackbar("red", res.data.msg );
                 this.overlay = false;
              }
            })
            .catch(error => {
              this.overlay = true;
              // window.console.log(error);
              this.message = "Something went wrong";
            })
            .finally(() => {
              "use strict";
              var overlay = false;
            });
        } else {
          //console.log("I am in else block :: " + this.user.uid);
          this.error = true;
          this.error_message = "Please Enter Valid Email ID..";
        }
      } else {
        //console.log("I am in else block :: " + this.user.uid);
        this.error = true;
        this.error_message = "Please Enter Valid Email ID..";
      }
    },
    // verifyotp() {
    //   if (this.perormOtpFormValidation()) {
    //     //console.log("I am in If block :: " + this.user.uid + " :: "+  this.user.otp  + " :: " + this.dbotp);
    //     this.overlay = true;
    //     axios
    //       .post("/login/verifyForgotPasswordOTP", this.user)
    //       .then(res => {
    //         //console.log(res.data);
    //         if (res.data.msg == "YES") {
    //           this.overlay = false;
    //           this.showSnackbar("#4caf50", "OTP verified successfully"); // show snackbar on error
    //           //console.log(res.data);
    //           this.hidden = false;
    //           this.hidden1 = false;
    //         } else if (res.data.msg == "NO") {
    //           this.overlay = false;
    //           this.showSnackbar("#b71c1c", "Wrong OTP! Please try again..."); // show snackbar on error
    //           document.getElementById("enterOTP").value = "";
    //         }
    //       })
    //       .catch(error => {
    //         window.console.log(error);
    //         this.message = "Something went wrong";
    //       })
    //       .finally(() => {
    //         "use strict";
    //         var overlay = false;
    //       });
    //     //
    //     // if(this.user.otp == this.dbotp) {
    //     //   this.hidden = false;
    //     //   this.$router.push("/change-password");
    //     //   //window.location="/change-password";
    //     // } else {
    //     //   this.message = "OTP not Match!!!";
    //     //   this.error1=true;
    //     //   this.error_message1='Please Enter Valid OTP..';
    //     // }
    //   } else {
    //     //console.log("I am in else block :: " + this.user.uid);
    //     this.error1 = true;
    //     this.error_message1 = "Please Enter OTP..";
    //   }
    // },
    perormEmailFormValidation() {
      if (this.user.uid == "") {
        return false;
      } else {
        return true;
      }
    },
    perormOtpFormValidation() {
      if (this.user.otp == "" && this.user.uid == "") {
        return false;
      } else {
        return true;
      }
    },
    btndisabled() {
      this.btnDisabled = false;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    changePassword() {
      //console.log("in changePassword");
      if (this.perormOtpFormValidation()) {
        if (this.checkBlankPwdFields()) {
          if (this.matchPwdFields()) {
            const data = {
              newpwd: this.newpwd,
              uid: this.user.uid,
              otp: this.user.otp
            };
            // console.log(data);
            this.overlay = true;
            axios.post("/login/verifyOTPandChangePassword", data).then(res => {
              //console.log(res.data);
              if (res.data.msg == "200") {
                this.overlay = false;
                //this.showSnackbar("#4caf50", "Password Changed Succussfully"); // show snackbar on error
                this.sleep(2000);
                alert("Password changed succussfully...");
                this.$router.push("/");
              } else if (res.data.msg == "ONM") {
                this.overlay = false;
                this.showSnackbar("#b71c1c", "Wrong OTP! Please try again..."); // show snackbar on error
                document.getElementById("enterOTP").value = "";
                // console.log(res.data);
              }
            });
          } else {
            this.overlay = false;
            this.showSnackbar("#b71c1c", "Password Doesn't match!"); // show snackbar on error
            document.getElementById("newpwd").value = "";
            document.getElementById("cnfmpwd").value = "";
          }
        } else {
          this.overlay = false;
          this.showSnackbar("#b71c1c", "Please provide Password!"); // show snackbar on error
          document.getElementById("newpwd").value = "";
          document.getElementById("cnfmpwd").value = "";
        }
      }
    },
    checkBlankPwdFields() {
      if (this.newpwd == "" && this.cnfmpwd == "") {
        return false;
      } else {
        return true;
      }
    },
    matchPwdFields() {
      if (this.newpwd == this.cnfmpwd) {
        return true;
      } else {
        return false;
      }
    },
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    clearErrorMsg(){
      this.error_message="";
    }
  },
  mounted() {
    this.message = localStorage.getItem("login-page-msg");
    this.user.uid = this.$route.params.uid;
  }
};
</script>
<style scoped>
.pull-right {
  position: relative;
  text-align: center;
}
.error-message {
  color: red;
  font-size: 14px;
  font-weight: 900;
  padding-left: 7%;
}
</style>